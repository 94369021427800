import React, { useState,  useRef, useEffect  } from 'react';
import { Flame, Award, Code, Briefcase, LinkedinIcon, Mail, GithubIcon, Send, Zap, Sparkles, MessageCircle } from 'lucide-react';

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState('awards');
  const [isChatOpen, setChatOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const toggleChat = () => {
    setChatOpen(!isChatOpen);
};
const research = [
  {
    title: "American Sign Language Translation - Deep Learning Researcher",
    description: "Trained the action based Recurrent Neural Network for american sign language translation comprise of 5 dense layers, with Pascal tree style number of nodes in each layer. Achieve 19% accuracy on 100 different words.",
    conference: "Yet to publish, Sept 2024 - present",
    url: "https://github.com/Khagendra01/Machine-Learning-Vision-for-American-Sign-Language-Detection"
  },
  {
    title: "Xito Recurrent Unit - Deep Learning Researcher",
    description: "Created a Gated Recurrent Unit (GRU) from scratch using numpy, modifying its architecture to achieve a 10% improvement in performance over the existing implementation. Invented and evaluated Recurrent Symbolic Networks architecture to tackle the vanishing gradient problem in RNNs, enhancing performance by 20% and interpretability for sequential data analysis.",
    conference: "Caldwell University Stem Summer Research, May 2024 - Aug 2024",
    url: "https://github.com/Khagendra01/Xito-Recurrent-Unit"
  },
  {
    title: "Plagiarism Detector - Machine Learning Researcher",
    description: "Trained a Neural Network architecture model using TensorFlow’s Keras library, comprising 550k parameters, aimed at detecting plagiarism in academic assignments, achieving a 95% accuracy rate. Collaborated with professors and fellow researchers to refine research methodologies, analyze results, and publish findings in the 28th annual conference of the Consortium for Computing Sciences.",
    conference: "28th annual conference of the Consortium for Computing Sciences, Jan 2024 - Apr 2024",
    url: "https://github.com/Khagendra01/CS-491"
  }
];


  const awards = [
    {
      title: "Winner - HackPrinceton Fall 2024, November 10",
      project: "Crystal Math",
      description: "Making Mathematics Visual, Intuitive, and Alive Through AI-Powered Animations",
      url: "https://dorahacks.io/buidl/19465"
    }
    ,
    {
      title: "Winner - HackNJIT Fall 2024, November 03",
      project: "Punkify",
      description: "Developed website for making therapy more accessible and engaging",
      url: "https://devpost.com/software/punkify"
    },
    {
      title: "Winner - HackRU Fall 2024, October 27",
      project: "ODG Vision",
      description: "Developed prototype for assisting visually imparied person",
      url: "https://devpost.com/software/odg-vision"
    },
    {
      title: "Winner - HopHacks Fall 2024, September 15",
      project: "E-Graveyard",
      description: "Built an innovative AI platform for digital conversations with deceased loved ones",
      url: "https://devpost.com/software/e-graveyard-where-your-loved-ones-are-remembered-forever"
    },
    
  ];

  const projects = [
    {
      title: "Good Fone",
      tech: "Raspberry Pi, Django, React.js, PostgreSQL",
      description: "Designed and developed a secure, web-based AI-powered phone platform aimed at providing a controlled mobile environment for children, with plans to transition to a Chrome OS-like operating system. Created AI applications using Langchain for RAG chains, leveraging OpenAI and Vertex AI to build custom AI for Finance Help, Homework, Persona, and Notes with accurate, reliable functionalities."
    },
    {
      title: "E-Graveyard",
      tech: "Django, React.js, PostgreSQL, Redis, Celery",
      description: "Built an innovative platform enabling users to engage in digital conversations with AI representations of deceased loved ones or inspirational figures, securing 2nd place at HopHacks Hackathon. Implemented voice cloning through Eleven Labs API, custom vector storage with pg-vector in PostgreSQL, and RAG architecture to deliver contextually relevant responses."
    },
    {
      title: "Professor AI",
      tech: "C#, Typescript, SQL, NLP, Azure",
      description: "Developed a professor-styled chatbot using Azure API and C# for backend development, React.js with TypeScript for frontend, and SQL for database management, achieving top 5 placement in the Microsoft AI Hackathon. Engineered natural language processing (NLP) using Python's NLTK library to improve the chatbot's understanding and response accuracy, enhancing user interaction and satisfaction by 25%."
    },
    {
      title: "Job Tracking App",
      tech: "Postgres, Redis, React.js, Django, Celery",
      description: "Developed a job listing web application that utilized email parsing with imaplib to automatically identify and leverage AI techniques to analyze email content and determine if it was related to a job application. Implemented the Django framework for the backend, PostgreSQL for the database, and React.js for a seamless user interface. Integrated Celery with Redis to offload time-consuming tasks from the Django application and execute them on a scheduled basis, improving overall system performance."
    },
    {
      title: "Plagiarism Detector",
      tech: "Tensorflow, Keras, Python, Matplotlib, Streamlit",
      description: "Trained a Neural Network architecture model using TensorFlow’s Keras library, comprising 550k parameters, aimed at detecting plagiarism in academic assignments, achieving a 95% accuracy rate. Collaborated with professors and fellow researchers to refine research methodologies, analyze results, and publish findings in the 28th annual conference of the Consortium for Computing Sciences."
    },
    {
      title: "ODG Vision",
      tech: "Python, OpenCV, Scikit-Learn, Raspberry Pi, Tensorflow",
      description: "Developed a prototype to assist visually impaired individuals by implementing YOLO object detection for recognizing various objects. Customized a Haar Cascade model to identify and train faces of friends and family. Utilized the Raspberry Pi Camera V2 module for testing and the Raspberry Pi for processing, running on a headless Ubuntu server. Integrated a speech-to-speech AI to enhance user interaction."
    },
    {
      title: "YouTube Content Creator",
      tech: "VB.NET, Video Production, Digital Literacy",
      description: "Developed and managed an educational YouTube channel, specializing in tutorials for virus removal, software development, and programming concepts. Produced and curated a library of 35 videos, gained a following of 498 subscribers, and achieved over 58,000 total views. Utilized VB.NET to develop a comprehensive tic-tac-toe game tutorial series, while simultaneously providing detailed guides on data recovery and system protection against ransomware to promote digital literacy and online safety."
    }
  ];
  

  const experience = [
    {
      title: "Software Developer Intern",
      company: "Digital Coach",
      period: "Oct 2024 - Present",
      description: "Developed an AI-driven e-learning application using Next.js, React, and Firebase, enhancing user engagement by 20% through interactive, real-time sentiment analysis in mock interviews. Integrated machine learning models in Python and TensorFlow for response analysis, delivering personalized scores and feedback that boosted user engagement by 25% and increased success rates by 15%."
    },
    {
      title: "AI Engineer Intern",
      company: "Radical AI",
      period: "Jun 2024 - Aug 2024",
      description: "Led the development and deployment of AI solutions using OpenAI Assistant API and Google Gemini, achieving a 30% increase in system capabilities. Managed end-to-end data pipeline processes, optimizing data quality for generative AI models and resulting in a 25% improvement in model performance and reliability. Spearheaded prompt engineering and performance testing, resulting in a 20% increase in technological advancements."
    },
    {
      title: "Machine Learning Researcher",
      company: "Caldwell University",
      period: "May 2024 - Aug 2024",
      description: "Invented and evaluated Recurrent Symbolic Networks architecture to tackle the vanishing gradient problem in RNNs, enhancing performance by 20% and interpretability for sequential data analysis. Collaborated with professors and fellow researchers to refine methodologies, analyze results, and publish findings at the 28th annual conference of the Consortium for Computing Sciences."
    },
    {
      title: "Software Engineer Intern",
      company: "Wicked Pay",
      period: "Jan 2024 - May 2024",
      description: "Created a batch-processing ETL pipeline that improved throughput by 30% and scalability by 25%, using Apache Airflow and Heroku. Engineered a seamless data transfer process leveraging REST API in C#, .Net Framework, JavaScript, and React.js for DOM manipulation, driving a 50% surge in user engagement. Deployed data pipeline to automate application deployment using Netlify and GitHub, improving efficiency and reducing manual workload."
    },
    {
      title: "Computer Science Tutor",
      company: "Caldwell University",
      period: "Oct 2023 - Present",
      description: "Mentored over 200 students in Object-Oriented Programming, Machine Learning, and Data Structures, implementing personalized tutoring techniques that raised average student grades by 30%. Optimized the learning environment by streamlining code review and introducing industry-standard tools such as Git and Docker, resulting in a 30% decrease in coding errors."
    },
    {
      title: "Software Engineering Intern",
      company: "The Vidhwan",
      period: "Jan 2021 - Dec 2022",
      description: "Engineered and implemented custom components for community platforms using JavaScript, HTML, CSS, and PHP (Laravel), leading to a 20% upswing in user population. Set up a payment system from Esewa and Khalti using curl API, facilitating over 1000 secure transactions per month."
    }
  ];
  
  const sendMessage = async () => {
    if (!message.trim()) return;

    const newMessage = { sender: "user", text: message, timestamp: new Date() };
    const updatedMessages = [...messages, newMessage];

    // Update local state
    setMessages(updatedMessages);
    setMessage("");
    setLoading(true);

    try {
      const userMessages = updatedMessages
      .filter((msg) => msg.sender === "user")
      .map((msg) => `user: ${msg.text}`)
      .join("\n");
      const response = await fetch("https://crystalmath.pythonanywhere.com/sendmsg", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: message,
          past_convo: userMessages,
        }),
      });

      const data = await response.json();

      if (response.ok && data.response) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "K-gen", text: data.response , timestamp: new Date() },
        ]);
      } else {
        throw new Error(data.error || "Failed to fetch response");
      }
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "K-gen", text: "Error fetching response. Try again later.", timestamp: new Date() },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const chatBoxRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  
  useEffect(() => {
    // Function to handle clicks outside the chat box
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        setChatOpen(false); // Close the chat box
      }
    };
  
    // Add both mouse and touch listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
  
    // Cleanup the event listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);
  
  const renderSection = () => {
    switch (activeSection) {
      case 'experience':
  return (
    <div className="bg-gradient-to-br from-gray-900 to-blue-900 p-8 rounded-3xl border border-blue-700/30 shadow-2xl">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300 mb-8">Experience</h2>
      <div className="space-y-6">
        {experience.map((exp, index) => (
          <div key={index} className="bg-gray-800/70 backdrop-blur-sm p-6 rounded-2xl border border-blue-500/20 hover:border-cyan-500/50 transition-all duration-300">
            <h3 className="text-2xl font-semibold text-cyan-300">{exp.title}</h3>
            <p className="text-blue-200 mt-1">{exp.company}</p>
            <p className="text-sm text-gray-400 mt-1">{exp.period}</p>
            <p className="text-gray-300 mt-3 leading-relaxed">{exp.description}</p>
          </div>
        ))}
      </div>
    </div>
  );

case 'about':
  return (
    <div className="bg-gradient-to-br from-gray-900 to-blue-900 p-8 rounded-3xl border border-blue-700/30 shadow-2xl space-y-6">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">About Me</h2>
      <p className="text-blue-200 text-lg leading-relaxed">
        Computer Science student at Caldwell University with strong focus on AI/ML and software development.
        Experience in building AI-powered applications, conducting machine learning research, and developing
        full-stack applications.
      </p>
      <div className="flex space-x-10 mt-8">
        <a href="https://www.linkedin.com/in/khagendrakhatri/" className="text-cyan-300 hover:text-cyan-500 transition-colors">
          <LinkedinIcon className="w-9 h-9" />
        </a>
        <a href="mailto:realkhagendra@gmail.com" className="text-blue-200 hover:text-blue-400 transition-colors">
          <Mail className="w-9 h-9" />
        </a>
        <a href="https://github.com" className="text-blue-200 hover:text-cyan-300 transition-colors">
          <GithubIcon className="w-9 h-9" />
        </a>
      </div>
    </div>
  );

case 'research':
  return (
    <div className="bg-gradient-to-br from-gray-900 to-blue-900 p-8 rounded-3xl border border-blue-700/30 shadow-2xl space-y-8">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">Research</h2>
      <div className="space-y-6">
        {research.map((item, index) => (
          <div key={index} className="bg-gray-800/70 backdrop-blur-sm p-6 rounded-2xl border border-blue-500/20 hover:border-cyan-500/50 transition-all duration-300">
            <h3 className="text-2xl font-semibold text-cyan-300">{item.title}</h3>
            <p className="text-blue-200 mt-3 leading-relaxed">{item.description}</p>
            <p className="text-sm text-blue-400 mt-4">Published in: {item.conference}</p>
            {item.url && (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-400 transition-colors duration-300 hover:underline mt-3 inline-block"
              >
                Visit the Github
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );

case 'awards':
  return (
    <div className="bg-gradient-to-br from-gray-900 to-blue-900 p-8 rounded-3xl border border-blue-700/30 shadow-2xl space-y-8">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">Awards & Recognition</h2>
      <div className="space-y-6">
        {awards.map((award, index) => (
          <div key={index} className="bg-gray-800/70 backdrop-blur-sm p-6 rounded-2xl border border-blue-500/20 hover:border-cyan-500/50 transition-all duration-300">
            <h3 className="text-2xl font-semibold text-cyan-300">{award.title}</h3>
            <p className="text-blue-200 mt-2">{award.project}</p>
            <p className="text-blue-200 mt-3 leading-relaxed">{award.description}</p>
            {award.url && (
              <a
                href={award.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-400 transition-colors duration-300 hover:underline mt-3 inline-block"
              >
                Visit the project
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );

case 'projects':
  return (
    <div className="bg-gradient-to-br from-gray-900 to-blue-900 p-8 rounded-3xl border border-blue-700/30 shadow-2xl space-y-8">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {projects.map((project, index) => (
          <div key={index} className="bg-gray-800/70 backdrop-blur-sm p-6 rounded-2xl border border-blue-500/20 hover:border-cyan-500/50 transition-all duration-300">
            <h3 className="text-2xl font-semibold text-cyan-300">{project.title}</h3>
            <p className="text-sm text-blue-400 mt-2">{project.tech}</p>
            <p className="text-blue-200 mt-3 leading-relaxed">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
      default:
        return null;
    }
  };


  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-black to-blue-900 text-white overflow-hidden relative">
      {/* Subtle grid background effect */}
      <div className="absolute inset-0 pointer-events-none opacity-10">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"></div>
      </div>
  
      {/* Responsive Navigation */}
      <nav className="relative z-10 bg-gray-900/50 backdrop-blur-sm py-4 md:py-6 border-b border-blue-700/20">
        <div className="max-w-7xl mx-auto px-4 flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0">
          <h1 className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">
            Khagendra Khatri
          </h1>
          <div className="flex space-x-4">
            <a href="https://www.linkedin.com/in/khagendrakhatri/" className="text-cyan-300 hover:text-cyan-100 transition">
              <LinkedinIcon className="w-5 h-5 md:w-6 md:h-6" />
            </a>
            <a href="https://github.com/khagendra01" className="text-cyan-300 hover:text-cyan-100 transition">
              <GithubIcon className="w-5 h-5 md:w-6 md:h-6" />
            </a>
          </div>
        </div>
      </nav>
  
      {/* Responsive Main Content */}
      <main className="relative z-10 max-w-7xl mx-auto px-4 py-6 md:py-12">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 md:gap-8">
          {/* Sidebar - Horizontal on mobile, Vertical on desktop */}
          <aside className="md:col-span-3 overflow-x-auto md:overflow-x-visible">
            <div className="flex md:flex-col space-x-4 md:space-x-0 md:space-y-4 pb-4 md:pb-0">
              {['experience', 'research', 'awards', 'projects', 'about'].map((section) => (
                <button
                  key={section}
                  onClick={() => setActiveSection(section)}
                  className={`flex-shrink-0 md:flex-shrink px-4 md:px-6 py-2 md:py-3 rounded-xl flex items-center space-x-2 md:space-x-3 transition-all duration-300 whitespace-nowrap
                    ${activeSection === section 
                      ? 'bg-cyan-500/20 text-cyan-300 border border-cyan-500/30' 
                      : 'hover:bg-gray-800/50 text-gray-400 hover:text-cyan-300'}`}
                >
                  {section === 'experience' && <Briefcase className="w-4 h-4 md:w-5 md:h-5" />}
                  {section === 'research' && <Flame className="w-4 h-4 md:w-5 md:h-5" />}
                  {section === 'awards' && <Award className="w-4 h-4 md:w-5 md:h-5" />}
                  {section === 'projects' && <Code className="w-4 h-4 md:w-5 md:h-5" />}
                  {section === 'about' && <Code className="w-4 h-4 md:w-5 md:h-5" />}
                  <span className="font-medium capitalize">{section}</span>
                </button>
              ))}
            </div>
          </aside>
  
          {/* Main Content Area */}
          <div className="md:col-span-9">
            {renderSection()}
          </div>
        </div>
      </main>
  
      {/* Responsive Chat Button */}
      <button 
        onClick={toggleChat}
        className="fixed right-4 md:right-8 bottom-4 z-50 md:bottom-8 bg-cyan-500 text-black rounded-full p-3 md:p-4 shadow-2xl hover:bg-cyan-400 transition-all duration-300 animate-pulse"
      >
        <span className="sr-only">Chat K-Gen Bot</span>
        <MessageCircle className="w-6 h-6" />
      </button>
  
      {/* Responsive Chat Box */}
      {isChatOpen && (
  <div className="fixed bottom-0 right-0 w-full md:w-96 h-[500px] z-50" ref={chatBoxRef}>
    {/* Holographic Overlay */}
    <div className="absolute inset-0 pointer-events-none">
      <div className="absolute -inset-4 bg-gradient-to-br from-cyan-500/20 to-purple-500/20 rounded-t-3xl blur-2xl opacity-30 animate-pulse"></div>
      <div className="absolute inset-0 border-4 border-cyan-300/30 rounded-t-3xl animate-border"></div>
    </div>

    {/* Chat Container */}
    <div className="relative z-50 bg-gray-900/80 backdrop-blur-xl rounded-t-3xl h-full flex flex-col shadow-2xl overflow-hidden">
      {/* Header */}
      <div className="bg-gradient-to-r from-cyan-500/30 to-purple-500/30 p-3 md:p-4 flex items-center justify-between">
        <h2 className="text-lg md:text-xl font-bold text-cyan-200 flex items-center">
          <Sparkles className="mr-2 text-cyan-300" />
          K-Gen
        </h2>
        <div className="flex space-x-2">
          <div className="w-2 h-2 md:w-3 md:h-3 bg-green-500 rounded-full animate-ping"></div>
          <div className="w-2 h-2 md:w-3 md:h-3 bg-green-500 rounded-full"></div>
        </div>
      </div>

      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto p-3 md:p-4 space-y-3 md:space-y-4 relative">
        {messages.map((msg, index) => (
          <div 
            key={index} 
            className={`flex ${msg.sender === "user" ? "justify-end" : "justify-start"}`}
          >
            <div 
              className={`
                max-w-[85%] md:max-w-[80%] p-2 md:p-3 rounded-2xl 
                ${msg.sender === "user" 
                  ? "bg-cyan-600/50 text-right" 
                  : "bg-purple-600/50 text-left"}
                transition-all duration-300 transform hover:scale-105
              `}
            >
              <div className="text-xs md:text-sm font-bold text-gray-200 mb-1">
                {msg.sender}
              </div>
              <div className="text-sm md:text-base text-gray-100">{msg.text}</div>
              <div className="text-xs text-gray-400 mt-1 opacity-70">
                {msg.timestamp.toLocaleTimeString()}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div className="p-3 md:p-4 border-t border-cyan-500/20 bg-gray-900/60">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Type a quantum message..."
            className="flex-1 bg-gray-800/50 border border-cyan-500/30 rounded-full px-3 md:px-4 py-2 text-sm md:text-base text-white 
              focus:outline-none focus:ring-2 focus:ring-cyan-500/50 transition-all duration-300"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && sendMessage()}
          />
          <button
            onClick={sendMessage}
            disabled={loading}
            className={`
              bg-gradient-to-r from-cyan-500 to-purple-500 
              text-white rounded-full p-2 transition-all duration-300
              ${loading 
                ? "opacity-50 cursor-not-allowed" 
                : "hover:from-cyan-600 hover:to-purple-600 hover:scale-110"}
            `}
          >
            {loading ? <Zap className="w-5 h-5 animate-spin" /> : <Send className="w-5 h-5" />}
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
}
export default Portfolio;